.container_header {
  display: flex;
  justify-content: space-around;
  background: #e8e9f8;
  text-align: initial;
  padding: 18px 24px;
  border-radius: 8px 8px 0 0;
}
.table_header {
  width: 23%;
  font-weight: bold;
  color: #2d357a;
}
.container_data {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 12px 24px;
  background-color: white;
}
.background_odd {
  background: #f9f9f9;
}
.font_data {
  width: 23%;
  color: #5d5d5d;
}
.img_search {
  width: 25px;
}

@media only screen and (max-width: 600px) {
  .wrapper_table {
    margin: 24px 0;
  }
  .none {
    display: none;
  }
  .container_header {
    padding: 12px 6px;
  }
  .container_data {
    padding: 12px 6px;
  }
  .table_header {
    max-width: 25%;
    min-width: 21%;
    font-size: 12px;
  }
  .font_data {
    max-width: 25%;
    min-width: 21%;
    font-size: 12px;
  }
  .img_search {
    width: 20px;
  }
}
