.container_qc {
  margin: 24px 24px 24px 204px;
}
.customer_width {
  min-width: 10%;
}
.column_qc_width {
  min-width: 19.5%;
  max-width: 19.5%;
}
.color_width {
  min-width: 20%;
}
.jumlah_width_qc {
  min-width: 11%;
}
.input_select_shift {
  box-shadow: 0 0 5pt 0.5pt #d3d3d3;
  border: none;
  height: 36px;
  border-radius: 3px;
  cursor: pointer;
  width: 144px;
  padding: 6px;
  background: white;
}
.input_berat_qc_width {
  width: 70px;
  height: 24px;
}
@media only screen and (max-width: 600px) {
  .container_qc {
    margin: 12px auto auto auto;
    padding: 12px 0px 0 0px;
  }
  .input_select_shift {
    width: 106px;
  }
  .min_width_27 {
    min-width: 27.5%;
    text-align: center;
  }
  .input_berat_qc_width {
    width: 24px;
  }
  .none {
    display: none;
  }
}
