.container_card_dashboard_ppic {
  width: 250px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px 18px;
  border-radius: 8px;
  margin: 0 16px 16px 0;
}
.image_logo_machine {
  width: 96px;
}
.title_machine {
  font-weight: bold;
  font-size: 18px;
  color: grey;
}
.flex_wrap {
  display: flex;
  flex-flow: wrap;
}
.flex_wrap_between {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}
.sub_title_card_dashboard {
  font-weight: bold;
  color: grey;
}
.width_dashboard_108 {
  width: 108px;
}
.width_dashboard_100_percent {
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .container_card_dashboard_ppic {
    width: 100%;
    margin: 12px 0;
  }
}
