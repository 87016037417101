.container_header_history {
  display: flex;
  justify-content: space-around;
  background: #e8e9f8;
  text-align: initial;
  padding: 18px 24px;
  border-radius: 8px 8px 0 0;
}
.table_header_history {
  min-width: 12.5%;
  font-weight: bold;
  color: #2d357a;
}
.container_data_history {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 12px 24px;
}
.color_history_approved {
  background: #006442;
}
.color_history_reject {
  background: #cf000f;
}
.background_odd_history {
  background: #f9f9f9;
}
.font_data_history {
  min-width: 12.5%;
  color: white;
}

@media only screen and (max-width: 600px) {
  .none {
    display: none;
  }
  .wrapper_table_history {
    margin: 24px 0;
  }
  .container_header_history {
    padding: 12px 6px;
  }
  .container_data_history {
    padding: 12px 6px;
  }
  .table_header_history {
    max-width: 20%;
    min-width: 18%;
    font-size: 12px;
  }
  .font_data_history {
    max-width: 20%;
    min-width: 18%;
    font-size: 12px;
  }
}
