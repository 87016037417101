.container_login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.login_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  background: white;
  padding: 24px;
  border-radius: 6px;
}
.title_login {
  font-size: 24px;
  font-weight: bold;
}
.logo_tim {
  width: 124px;
  margin-bottom: 12px;
}
.input_login {
  margin-bottom: 24px;
  border: none;
  border-bottom: 2px solid lightgray;
  padding: 8px 4px;
  width: 100%;
}
.button_login {
  width: 100%;
  margin-top: 12px;
  cursor: pointer;
  border: none;
  padding: 12px 0;
  border-radius: 6px;
  background: #2d357a;
  color: white;
}
@media only screen and (max-width: 600px) {
  .login_card {
    width: 70%;
  }
}
