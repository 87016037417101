.container_card_approval_laporan {
  display: flex;
  flex-flow: wrap;
}
.wrapper_card_approval_laporan {
  width: 320px;
  background: white;
  padding: 0 18px 18px 18px;
  border-radius: 8px;
  margin: 0 16px 16px 0;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.section_approval_laporan {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card_approval_top_plate {
  background: white;
  padding: 0 18px 18px 18px;
  margin: 0 16px 18px 0;
  width: 320px;
  border-radius: 8px;
}
.flex_justify_between {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .wrapper_card_approval_laporan {
    width: 91%;
    margin: 0 0 16px 0;
  }
  .card_approval_top_plate {
    width: 91%;
    margin: 12px 0 6px 0;
  }
}
