.container_memo_ppic {
  margin: 24px 24px 24px 204px;
}
.button_submit_memo {
  width: 90px;
  height: 32px;
  cursor: pointer;
  margin: 24px 0;
  float: right;
  border: none;
  color: white;
  background: red;
  border-radius: 4px;
}
.button_tambah_kurang {
  background: #2d357a;
  margin: 12px 12px 12px 0;
  height: 32px;
  cursor: pointer;
  color: white;
  border: none;
  border-radius: 4px;
  width: 78px;
}
.date_pick_memo_ppic {
  width: 132px;
  height: 24px;
  padding: 6px;
  border: none;
  background: #e8e9f8;
  margin: 0 0 24px 0;
  border-radius: 4px;
  box-shadow: 0 0 5pt 0.5pt #d3d3d3;
}

@media only screen and (max-width: 600px) {
  .container_memo_ppic {
    margin: 12px auto auto auto;
    padding: 24px 16px 0 16px;
  }
  .date_pick_memo_ppic {
    width: 106px;
  }
  .button_submit_memo {
    width: 76px;
  }
}
