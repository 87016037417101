.container_popup {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.wrapper_popup {
  position: relative;
  width: 43%;
  margin: 0 auto;
  height: auto;
  margin-top: calc(124vh - 95vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  overflow: auto;
}

@media only screen and (max-width: 600px) {
  .wrapper_popup {
    width: 80%;
    margin-top: calc(100vh - 95vh - 20px);
  }
}
