.container_card_kerusakan {
  display: flex;
  flex-wrap: wrap;
}
.wrapper_card_kerusakan {
  display: flex;
  align-items: center;
  width: 268px;
  padding: 12px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 0 24px 24px 0;
  cursor: pointer;
}
.style_mesin_laporan_kerusakan {
  text-align: center;
  margin-right: 18px;
  line-height: 32px;
  font-size: 24px;
}

@media only screen and (max-width: 600px) {
  .container_card_kerusakan {
    padding: 18px 0;
  }
  .wrapper_card_kerusakan {
    margin: 0 0 24px 0;
    width: 100%;
  }
  .style_mesin_laporan_kerusakan {
    font-size: 18px;
  }
}
