.card_container {
  width: 120px;
  background: #fefefe;
  border-radius: 6px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 3px solid white;
  margin: 4px 18px 18px 0;
  padding-top: 6px;
}
.logo_company {
  max-width: 110px;
  height: 75px;
}
.name_company {
  font-weight: bold;
  letter-spacing: 1px;
  color: #5d5d5d;
}
.card_active {
  border: 3px solid #2d357a;
}
@media only screen and (max-width: 600px) {
  .card_container {
    width: 75px;
    margin: 4px 12px 12px 0;
    min-height: 50px;
  }
  .logo_company {
    max-width: 50px;
    height: 40px;
  }
  .name_company {
    font-size: 12px;
  }
}
