.wrapper_popup_list_produk_sp {
  position: relative;
  width: 43%;
  margin: 0 auto;
  height: auto;
  margin-top: calc(124vh - 95vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  overflow: auto;
}

@media only screen and (max-width: 600px) {
  .font_14_mobile_sp {
    font-size: 14px;
  }
}
