.container_dashboard {
  margin: 12px 24px 24px 204px;
}
.input_dashboard_operator {
  height: 24px;
  border-radius: 4px;
  border: 1px solid lightgrey;
  padding: 2px 6px;
  width: 80%;
}
.button_export_sp {
  background: green;
  color: white;
  padding: 12px;
  border-radius: 6px;
  margin-right: 12px;
  width: 112px;
  height: 48px;
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  justify-content: center;
}
.sticky_filter {
  display: flex;
  flex-wrap: wrap;
  position: sticky;
  top: 0;
  background: #f6f6fc;
}

@media only screen and (max-width: 600px) {
  .sticky_filter {
    top: 42px;
  }
  .container_dashboard {
    margin: 12px auto auto auto;
    padding: 36px 16px 0 16px;
  }
}
