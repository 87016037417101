.flex_center {
  display: flex;
  align-items: center;
}
.width_number_mesin_qc {
  min-width: 9% !important;
}
.margin_right_27_qc {
  margin-right: 24px;
}
@media only screen and (max-width: 600px) {
  .width_number_mesin_qc {
    max-width: 25% !important;
    min-width: 15% !important;
    font-size: 12px;
    text-align: center;
  }
  .min_width_qc_27 {
    min-width: 27.5% !important;
    text-align: center;
  }
  .none {
    display: none;
  }
  .margin_right_27_qc {
    margin-right: 12px;
  }
}
