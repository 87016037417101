.wrapper_request_lembur {
  margin-right: 12px;
}
.width_select_lembur {
  width: 248px;
}
.input_select_mesin {
  box-shadow: 0 0 5pt 0.5pt #d3d3d3;
  border: none;
  height: 36px;
  border-radius: 3px;
  cursor: pointer;
  padding: 6px;
  background: white;
}
.input_select_memo_ppic {
  box-shadow: 0 0 5pt 0.5pt #d3d3d3;
  border: none;
  height: 36px;
  border-radius: 3px;
  cursor: pointer;
  padding: 6px;
  background: white;
}
.font_size_lembur {
  font-size: 14px;
  padding: 12px 6px;
  line-height: 18px;
}
.table_header_lembur {
  font-weight: bold;
  color: #2d357a;
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  .width_100_mobile {
    width: 100%;
  }
  .font_size_lembur {
    font-size: 11px;
  }
  .table_header_lembur {
    font-size: 11px;
  }
}
