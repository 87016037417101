.container_inventory {
  margin: 24px 24px 24px 204px;
}
.title_inventory {
  letter-spacing: 2px;
  font-size: 24px;
  font-weight: bold;
  color: #2d357a;
}
.flex_wrap {
  display: flex;
  flex-flow: wrap;
}
.button_kirim_po {
  margin-top: 0;
  background: red;
  padding: 6px 0px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 6px;
}
.font_size_td_mobile {
  padding: 24px;
  white-space: nowrap;
}
.width_popup {
  width: 30%;
}

.popUp_history_search {
  height: 580px;
}
.search_box_inventory {
  height: 30px;
  padding: 2px 6px;
  border-top: 2px solid #81bfda;
  border-bottom: 2px solid #81bfda;
  border-left: 2px solid #81bfda;
  border-right: none;
  border-radius: 8px 0 0 8px;
  width: 200px;
}
.search_image_inventory {
  place-content: center;
  height: 30px;
  border: none;
  background: white;
  border-top: 2px solid #81bfda;
  border-bottom: 2px solid #81bfda;
  border-right: 2px solid #81bfda;
  border-left: none;
  padding: 2px 4px;
  border-radius: 0 8px 8px 0;
}
.btn_reset_search_inventory {
  background: #81bfda;
  color: white;
  padding: 9px;
  margin-left: 6px;
  border-radius: 8px;
  cursor: pointer;
}
.margin_kirim_po {
  margin-right: 12px;
}
.input_kirim_po {
  width: 240px;
  box-shadow: 0 0 5pt 0.5pt #d3d3d3;
  border: none;
  height: 30px;
  border-radius: 3px;
  padding: 6px;
}
.export_btn_inventory {
  background: green;
  border: none;
  border-radius: 8px;
  padding: 11px;
  cursor: pointer;
  color: white;
}

*:focus {
  outline: none;
}

.sidebar-monitoring {
  position: sticky;
  left: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: 12px;
  padding-right: 12px;
}

@media only screen and (max-width: 600px) {
  .width_mobile_select_vp {
    width: 138px !important;
  }
  .width_popup {
    width: 80%;
  }
  .flex_wrap {
    margin: 24px 0;
  }
  .container_inventory {
    margin: 12px auto auto auto;
    padding: 24px 16px 0 16px;
  }
  .title_inventory {
    font-size: 16px;
    margin-bottom: 0;
  }
  .table_inventory_mobile {
    overflow: hidden;
    overflow-x: auto;
    clear: both;
    width: 100%;
  }
  .font_size_td_mobile {
    font-size: 14px;
  }
  .popUp_history_search {
    overflow-y: scroll;
    height: 480px;
  }
  .margin_kirim_po {
    margin-right: 0px;
  }
  .export_btn_inventory {
    margin-left: 0px;
    margin-bottom: 12px;
  }
}
