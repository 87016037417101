.container_history {
  margin: 24px 24px 24px 204px;
}
.wrapper_sub_menu_history {
  display: flex;
  justify-content: space-around;
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: bold;
  flex-wrap: wrap;
}
.sub_menu_history {
  padding: 6px;
  border-bottom: 3px solid #f6f6fc;
  color: #2d357a;
  font-weight: normal;
  cursor: pointer;
}
.border_active_history {
  border-bottom: 3px solid #2d357a;
  font-weight: bold;
}
table {
  border-spacing: 0;
}
th {
  background: #e8e9f8;
  padding: 18px 24px;
  position: sticky;
  top: 0;
}
td {
  padding-top: 18px;
  text-align: center;
  vertical-align: top;
}
.card_ganti_mold {
  width: 550px;
  background: white;
  border-radius: 6px;
  padding: 12px;
  margin: 0 16px 24px 0;
}
.kirim_button_mobile {
  margin-left: 83% !important;
  margin-bottom: 12px !important;
}
.font_14_history {
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  .none {
    display: none;
  }
  .container_data_memo_ppic {
    font-size: 11px;
  }
  .min_width_15 {
    max-width: 25% !important;
    min-width: 15% !important;
    font-size: 12px;
    text-align: center;
  }
  .min_width_20 {
    max-width: 25% !important;
    min-width: 20% !important;
    font-size: 12px;
    text-align: center;
  }
  .min_width_17 {
    min-width: 17% !important;
    text-align: center;
  }
  .min_width_27 {
    min-width: 27.5% !important;
    text-align: center;
  }
  .container_history {
    margin: 12px auto auto auto;
    padding: 24px 16px 0 16px;
  }
  .wrapper_sub_menu_history {
    font-size: 14px;
    margin-bottom: 12px;
  }
  .card_ganti_mold {
    margin: 0 0 24px 0;
  }
  .kirim_button_mobile {
    margin-left: 77% !important;
    margin-bottom: 12px !important;
  }
  .font_14_history {
    font-size: 12px;
  }
}
