.container_header_list_bahan {
  display: flex;
  justify-content: space-around;
  background: #e8e9f8;
  text-align: initial;
  padding: 18px 24px;
  border-radius: 8px 8px 0 0;
}
.table_header_list_bahan {
  min-width: 25%;
  font-weight: bold;
  color: #2d357a;
}
.container_data_list_bahan {
  display: flex;
  justify-content: space-around;
  padding: 12px 24px;
  background-color: white;
  align-items: center;
}

.font_data_list_bahan {
  min-width: 25%;
  color: #5d5d5d;
}

.background_odd_list_bahan {
  background: #f9f9f9;
}

.button_delete_list_bahan {
  border: none;
  background: red;
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .container_header_list_bahan {
    padding: 12px 6px;
  }
  .container_data_list_bahan {
    padding: 12px 6px;
  }
  .table_header_list_bahan {
    max-width: 25%;
    min-width: 21%;
    font-size: 12px;
  }
  .font_data_list_bahan {
    max-width: 25%;
    min-width: 21%;
    font-size: 12px;
  }
  .button_delete_list_bahan {
    padding: 4px 6px;
  }
}
