.sticky_column_number {
  left: 204px;
  border: none;
}
.sticky_column_problem {
  left: 278px;
  border: none;
  white-space: nowrap;
  text-align: left;
}

.border_right_cs:last-child {
  border-right: 1px solid black;
  border-top: 1px solid black;
}
@media only screen and (max-width: 600px) {
  .sticky_column_number {
    left: 0px;
  }
  .sticky_column_problem {
    left: 68px;
    white-space: initial;
  }
}
