.parent_logo {
  display: flex;
  position: fixed;
  background: white;
  height: 84px;
  justify-content: center;
  align-items: center;
  width: 180px;
  top: 0;
}
.width_logo {
  width: 80px;
}
.wrapper_sub_menu {
  overflow: hidden;
  background-color: #2d357a;
  position: fixed;
  top: 46px;
  height: 100vh;
  border-radius: 0 6px 0 0;
  z-index: 1;
}

.menu_button {
  display: none;
}
.parent_menu {
  background-color: #2d357a;
  width: 180px;
  border-radius: 0 6px 0 0;
  top: 82px;
  position: fixed;
  bottom: 0;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.menu {
  padding: 12px 0 12px 0;
  cursor: pointer;
  margin-left: 12px;
  color: white;
}
.menu_active {
  padding: 12px 0px 12px 12px;
  cursor: pointer;
  background: white;
  border-radius: 6px 0 0 6px;
  margin-left: 12px;
  color: black;
}
.button_logout {
  cursor: pointer;
  margin-left: 12px;
  padding: 6px 16px;
  background: red;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  position: fixed;
  bottom: 16px;
}
.wrapper_menu {
  padding: 0 0 124px 0;
}

/* button switch */
.toggle-button-cover {
  display: table-cell;
  position: relative;
  width: 200px;
  height: 98px;
  box-sizing: border-box;
}

.button-cover {
  height: 68px;
  margin: 12px;
  background-color: #fff;
  box-shadow: 0 10px 20px -8px #c5d6d6;
  border-radius: 4px;
}

.button-cover:before {
  counter-increment: button-counter;
  position: absolute;
  right: 0;
  bottom: 0;
  color: #d7e3e3;
  font-size: 12px;
  line-height: 1;
  padding: 5px;
}

.button-cover,
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: relative;
  top: 50%;
  width: 74px;
  height: 36px;
  margin: -20px auto 0 auto;
  overflow: hidden;
}

.button.r,
.button.r .layer {
  border-radius: 100px;
}

.button.b2 {
  border-radius: 2px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #ebf7fc;
  transition: 0.3s ease all;
  z-index: 1;
}

/* Button 1 */
#button-1 .knobs:before {
  content: "ON";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 20px;
  height: 10px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  background-color: #03a9f4;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

#button-1 .checkbox:checked + .knobs:before {
  content: "OFF";
  left: 42px;
  background-color: #f44336;
}

#button-1 .checkbox:checked ~ .layer {
  background-color: #fcebeb;
}

#button-1 .knobs,
#button-1 .knobs:before,
#button-1 .layer {
  transition: 0.3s ease all;
}

@media only screen and (max-width: 600px) {
  .parent_logo {
    width: 70px;
    position: fixed;
    top: 45px;
  }
  .width_logo {
    width: 70px;
  }
  .parent_menu {
    width: 70px;
    top: 120px;
    position: fixed;
  }
  .menu {
    font-size: 12px;
    margin: 6px 0 6px 6px;
  }
  .menu_active {
    padding: 6px 0px 6px 6px;
    margin-left: 6px;
    font-size: 12px;
  }
  .button_logout {
    font-size: 12px;
    padding: 6px 10px;
    margin-left: 6px;
  }
  .menu_button {
    display: initial;
    position: fixed;
    font-size: 12px;
    margin: 0 16px;
    border: 1px solid black;
    width: 48px;
    padding: 6px;
    text-align: center;
    border-radius: 4px;
    background: white;
    opacity: 1;
  }
  .wrapper_menu {
    padding: 0 0 164px 0;
  }
  .none {
    display: none;
  }
  .button-cover {
    margin: 0;
  }
  .button {
    margin: -20px auto 0 0;
  }
}
