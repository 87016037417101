.table_header_list_produk {
  min-width: 20%;
  font-weight: bold;
  color: #2d357a;
  text-align: center;
}
.font_data_produk {
  color: #5d5d5d;
  font-weight: normal;
}
.width_delete_button {
  min-width: 10%;
}
.width_30_list_produk {
  min-width: 30%;
}
.width_edit_logo {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .table_header_list_produk {
    max-width: 23.33%;
    min-width: 14%;
    font-size: 12px;
  }
  .none {
    display: none;
  }
}
