.container_request_stock_in {
  margin: 24px 24px 24px 204px;
}
.input_select {
  box-shadow: 0 0 5pt 0.5pt #d3d3d3;
  border: none;
  height: 42px;
  border-radius: 3px;
  cursor: pointer;
  width: 180px;
  padding: 6px;
  background: white;
}
.input_jumlah {
  box-shadow: 0 0 5pt 0.5pt #d3d3d3;
  border: none;
  height: 30px;
  border-radius: 3px;
  width: 180px;
  padding: 6px;
}
.wrapper_input {
  margin-right: 24px;
}
.wrapper_button_kirim_request_in {
  display: flex;
  align-items: flex-end;
  margin-bottom: 3px;
}
.wrapper_radio {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.flex_wrap_request {
  display: flex;
  flex-wrap: wrap;
}
.button_kirim_request_in {
  width: 80px;
  background: #2d357a;
  color: white;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}
.text_area {
  height: 80px;
  resize: none;
}
.font_weight_bold {
  font-weight: bold;
}
@media only screen and (max-width: 600px) {
  .container_request_stock_in {
    margin: 12px 12px 12px 80px;
  }
  .input_select {
    width: 100%;
  }
  .wrapper_input {
    width: 100%;
    margin: 0;
  }
  .column_mobile {
    flex-direction: column;
  }
  .wrapper_button_kirim_request_in {
    margin: 12px 0 0 2px;
    justify-content: end;
  }
  .text_area {
    width: 95%;
  }
  .input_jumlah {
    width: 96%;
  }
  .flex_wrap_request {
    display: inherit;
    margin: 24px 0px;
  }
}
