.container_list_bahan {
  margin: 24px 24px 24px 204px;
}

.wrapper_tambah_list {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #26a65b;
  width: 100px;
  height: 36px;
  color: white;
  border-radius: 6px;
  padding-bottom: 6px;
}

.wrapper_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.font_tambah_button {
  font-size: 16px;
  margin-top: 4px;
}

.icon_add_list {
  font-size: 24px;
}

.wrapper_button_submit_popup {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.wrapper_content_popup {
  display: flex;
  flex-wrap: wrap;
}

.button_popup {
  border: 1px solid black;
  height: 32px;
  width: 78px;
  border-radius: 6px;
  background: none;
  cursor: pointer;
  margin-left: 6px;
}

.background_red {
  background: red;
  color: white;
  border: none;
}

.input_new_bahan {
  box-shadow: 0 0 5pt 0.5pt #d3d3d3;
  border: none;
  height: 30px;
  border-radius: 3px;
  width: 180px;
  padding: 6px;
  background: white;
}

@media only screen and (max-width: 600px) {
  .container_list_bahan {
    margin: 12px auto auto auto;
    padding: 24px 16px 0 16px;
  }
  .icon_add_list {
    font-size: 18px;
  }
  .font_tambah_button {
    font-size: 14px;
  }
  .wrapper_tambah_list {
    height: 26px;
    width: 90px;
  }

  .wrapper_button_submit_popup {
    margin-top: 18px;
  }
}
