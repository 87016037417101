.font_td_laporan_bahan_baku {
  font-size: 16px;
}
.text_center_bahan_baku {
  text-align: center;
}
.table_laporan_bahan_baku {
  position: sticky;
  z-index: -1;
}

@media only screen and (max-width: 600px) {
  .font_td_laporan_bahan_baku {
    font-size: 12px;
  }
  .text_center_bahan_baku {
    text-align: left;
  }
  .width_date_laporan {
    width: 97%;
  }
  .table_laporan_bahan_baku {
    position: absolute;
    overflow: hidden;
    overflow-x: auto;
    clear: both;
    width: 90%;
  }
}
