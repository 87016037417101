.container-card-karantina {
  border: 1px solid darkgray;
  margin: 12px 18px 12px 0;
  border-radius: 4px;
  padding: 0 12px;
  cursor: pointer;
  width: 548px;
}

.width-table-karantina {
  width: 84%;
}

@media only screen and (max-width: 600px) {
  .container-card-karantina {
    margin: 0 0 12px 0;
  }
  .width-table-karantina {
    width: 90%;
  }
  .lineHeight-card-mobile {
    line-height: 24px;
    font-size: 14px;
  }
  .flex-mobile-karantina {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
  }
}
